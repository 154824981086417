import { APP_CAREPROVIDER } from "@recare/core/consts";
import { AppType, TourElement } from "@recare/core/types";
import { useEnvContext } from "context/EnvContext";
import { NewBadge } from "ds/components/Badge";
import { PRIMARY_COLOR, PROVIDER_PRIMARY_COLOR } from "ds/materials/colors";
import { margin } from "ds/materials/metrics";
import { Link } from "ds/materials/typography";
import { CSSProperties, ComponentType } from "react";

const getColor = (
  app: AppType,
  color?: CSSProperties["color"],
): CSSProperties["color"] => {
  if (color) return color;
  if (app === APP_CAREPROVIDER) return PROVIDER_PRIMARY_COLOR;
  return PRIMARY_COLOR;
};

export default function LinkComponent({
  color,
  disabled,
  fontSize,
  fontWeight,
  Icon,
  onAction,
  value,
  withNewBadge,
  style = {},
  tour,
}: {
  Icon?: ComponentType<any>;
  color?: CSSProperties["color"];
  disabled?: boolean;
  fontSize?: string;
  fontWeight?: number;
  onAction?: () => void;
  style?: CSSProperties;
  tour?: TourElement;
  value: string;
  withNewBadge?: boolean;
}) {
  const { app } = useEnvContext();
  const linkColor = getColor(app, color);

  return (
    <Link
      onClick={disabled ? undefined : onAction}
      color={linkColor}
      disabled={disabled}
      fontSize={fontSize}
      fontWeight={fontWeight}
      style={{ display: "flex", alignItems: "center", ...style }}
      {...tour}
    >
      {value}
      {withNewBadge ? <NewBadge marginWrapper={margin(0, 0, 0, 1)} /> : null}
      {Icon && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon htmlColor={linkColor} fontSize="inherit" />
        </div>
      )}
    </Link>
  );
}
