import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ORIGIN_RECEIVER,
  ORIGIN_SENDER,
  SEARCH_TYPE_CARE,
} from "@recare/core/consts";
import {
  getName,
  getProviderSearchAccountName,
} from "@recare/core/model/accounts";
import { useGetOntology } from "@recare/core/model/utils/ontologies/hooks";
import { capitaliseFirst } from "@recare/core/model/utils/strings";
import { GetOntologyType, LastSeen, SearchType } from "@recare/core/types";
import { useTranslations } from "@recare/translations";
import Translations from "@recare/translations/types";
import {
  GREY_600,
  ICON_GREY,
  KEYBOARD_FOCUS_OUTLINE,
} from "ds/materials/colors";
import { VerticalLayout } from "ds/materials/layouts";
import { dp, margin, padding, sizing } from "ds/materials/metrics";
import {
  Caption,
  CaptionProps,
  FONT_SIZE_12,
  FONT_WEIGHT_REGULAR,
} from "ds/materials/typography";
import { HTMLAttributes, useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

const FlexedRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const getTranslationForOrigin = (
  getOntology: GetOntologyType,
  translations: Translations,
  origin: number,
  searchType: SearchType | null | undefined,
) => {
  switch (origin) {
    case ORIGIN_RECEIVER:
      return ` - ${capitaliseFirst(
        getOntology({
          type: "receiverTypeSingular",
          key: searchType || SEARCH_TYPE_CARE,
        }),
      )}`;
    case ORIGIN_SENDER:
      return ` - ${translations.messenger.sender.clinic}`;
    default:
      return "";
  }
};

const StyledSeeMoreButton = styled.button`
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  margin-top: ${margin(0.25)};
  padding: ${padding(0, 0.25)};

  &:focus-visible {
    outline: ${dp(2)} solid ${KEYBOARD_FOCUS_OUTLINE};
  }
`;

export function SeenTag({
  lastSeens,
  patientId,
  searchType,
}: {
  lastSeens: LastSeen[] | null | undefined;
  patientId: string | undefined;
  searchType?: SearchType | null | undefined;
}) {
  const contentId = `content-${uuidv4()}`;
  const translations = useTranslations();
  const [expanded, setExpanded] = useState<boolean>(false);
  const ExpandIcon = expanded ? ExpandLessIcon : ExpandMoreIcon;
  const iconStyle = { width: sizing(2), height: sizing(2) };
  const getOntology = useGetOntology();

  const captionProps: CaptionProps & HTMLAttributes<HTMLSpanElement> = {
    color: GREY_600,
    style: {
      margin: margin(0.5, 0, 0, 0),
      fontWeight: FONT_WEIGHT_REGULAR,
      fontSize: FONT_SIZE_12,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
  };

  if (!lastSeens?.length) {
    return (
      <Caption as="p" {...captionProps}>
        {translations.messenger.notSeen}
      </Caption>
    );
  }

  return (
    <>
      <StyledSeeMoreButton
        aria-controls={contentId}
        aria-expanded={expanded}
        aria-label={
          translations.messenger.accessibility.messageSeenNotification
        }
        onClick={() => setExpanded((previousState) => !previousState)}
      >
        <Caption {...captionProps}>
          <FlexedRow>
            {`${translations.messenger.seen} (${lastSeens.length})`}
            <ExpandIcon style={iconStyle} htmlColor={ICON_GREY} />
          </FlexedRow>
        </Caption>
      </StyledSeeMoreButton>
      <VerticalLayout
        margin={margin(0.5, 2, 0, 0)}
        id={contentId}
        visibility={expanded ? "visible" : "hidden"}
      >
        {lastSeens.map((seen) => (
          <Caption
            as="p"
            key={seen.timestamp}
            color={GREY_600}
            style={{
              margin: margin(0),
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            {`${getName(seen.account, getOntology, {
              withAcademicTitle: false,
              withSalutation: false,
              providerSearchAccountName: getProviderSearchAccountName({
                translations,
                patientId,
              }),
            })}${getTranslationForOrigin(
              getOntology,
              translations,
              seen.origin,
              searchType,
            )}`}
          </Caption>
        ))}
      </VerticalLayout>
    </>
  );
}
